import React from 'react'
import { css } from '@emotion/core'
import Helmet from 'react-helmet'
import imageHenrik from '../images/employee_hej.jpg'
import Layout from '../components/layout'

// Styles
import common from '../styles/common'
import colors from '../styles/colors'

// Components
import Hero from '../components/hero'
import Button from '../components/button'
import FreeDemoModal from '../components/freeDemoModal'

class LedigeStillingerPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalActive: false,
    }
  }
  render() {
    return (
      <Layout>
        <div css={container}>
          <Helmet>
            <title>Ekko App - Introduktion til en medarbejderapp</title>
          </Helmet>
          <Hero title="Introduktion til en medarbejderapp" />

          <div className="wrapper">
            <div className="container full-width ">
              <div className="inner-container">
                <video className="hero-video u_mb-xl" controls>
                  <source
                    src="https://res.cloudinary.com/toecho/video/upload/vc_h264/Introduktion_til_en_medarbejderapp.mp4"
                    type="video/mp4"
                  />
                </video>

                <h2 className="u_mb-xs">Hvad er en medarbejderapp?</h2>
                <p className="u_mb-lg lead">
                  Når dine medarbejdere arbejder adspredt på tværs af flere
                  lokationer så har et værktøj som en medarbejderapp en særlig
                  værdi for din virksomhed. Men hvad er en medarbejderapp og
                  hvordan skaber den værdi for dine medarbejdere og din
                  virksomhed? Det svare Henrik Jensen, CEO i ToEcho på denne
                  video.
                </p>

                <h2 className="u_mb-xs">Prøv en medarbejderapp gratis</h2>
                <p className="lead u_mb-lg">
                  Med en gratis demo kan du prøve hvor effektivt og let
                  kommunikationen bliver i din virksomhed med en medarbejderapp.
                  Vil du høre hvordan din virksomhed konkret kan få gavn af en
                  medarbejderapp, så book et uforpligtende møde med Henrik
                  Jensen
                </p>

                <Button
                  type="dark fill"
                  text="Gratis demo"
                  onClick={() => this.setState({ modalActive: true })}
                />

                <Button
                  image={imageHenrik}
                  rel="noreferrer"
                  target="_blank"
                  useAnchorTag={true}
                  href="mailto:hej@toecho.dk?subject=Jeg vil gerne booke et møde&body=Hej Henrik, Jeg vil gerne booke et møde med dig og høre mere om ekko app. Jeg er særlig interesseret i at høre om .. "
                  type="dark outline"
                  text="Book et møde med Henrik Jensen"
                />
              </div>
            </div>
          </div>

          <FreeDemoModal
            active={this.state.modalActive}
            deactivate={() => {
              this.setState({ modalActive: false })
            }}
          />
        </div>
      </Layout>
    )
  }
}

const container = () => css`
  h2 {
    color: ${colors.deepSeaBlue};
  }

  video.hero-video {
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 7px 16px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.15);
    margin-top: -39%;
    position: relative;
  }

  .wrapper {
    @media screen and (min-width: ${common.breakpointMedium + 1}px) {
      padding: 100px 0;
    }
    @media screen and (max-width: ${common.breakpointMedium}px) {
      padding: 50px 0 50px 0;
    }

    .container {
      padding: 80px 0;

      &:last-child {
        border-bottom: 0px;
      }
    }
  }

  @media screen and (min-width: 501px) {
    button,
    a.button {
      margin: 0 0.5rem 1rem 0;
    }
  }

  @media screen and (max-width: 500px) {
    button,
    a.button {
      display: block;
      margin: 0 0 1rem 0;
    }
  }
  a {
    color: ${colors.seaBlue};
    font-weight: 500;
  }
`

export default LedigeStillingerPage
